<template>

  <div class="py-20" style="background: linear-gradient(90deg, #667eea 0%, #764BA2 100%)">

        <span class="closeAbout absolute left-0 top-0 h-16 w-16 ..."><router-link to="/"><svg
            xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"/>
</svg>
        </router-link></span>
    <div class="w-full bg-white rounded shadow-lg p-8 m-4 md:max-w-sm md:mx-auto">
      <span
          class="py-1 md:py-3 pl-1 text-grey-900 no-underline hover:text-red-800 border-b-4 border-gray-900 hover:border-red-800">CV LINK</span>
      <br/>
      <br/>
      <!--
        <div class="flex justify-between ...">
      -->
      <h4 class="bg-grey font-bold rounded-full py-4 px-8 shadow-lg uppercase tracking-wider">Select category</h4>
      <br/>
      <div>
        <button id="location-sound" v-on:click="changeCurrentCategory('location-sound')"
                class="transform hover:scale-110 motion-reduce:transform-none p-1">
          Location Sound
        </button>
      </div>
      <div>
        <button id="editorial-sound" v-on:click="changeCurrentCategory('editorial-sound')"
                class="transform hover:scale-110 motion-reduce:transform-none p-1">Editorial Sound
        </button>
      </div>
      <div>
        <button id="music-and-score" v-on:click="changeCurrentCategory('music-and-score')"
                class="transform hover:scale-110 motion-reduce:transform-none p-1">Music and Score
        </button>
      </div>
    </div>

    <div v-if="dataReady" class="container w-100 lg:w-4/5 mx-auto flex flex-col">
      <!-- card -->
      <div v-for="projectEntry in projectCategoryData.project" v-bind:key="projectEntry.index" class="flex flex-col md:flex-row overflow-hidden
                                        bg-white rounded-lg shadow-xl  mt-4 w-100 mx-2">
        <!-- media -->
        <div class="h-64 w-auto md:w-1/2">
          <img class="inset-0 h-full w-full object-cover object-center" :src="projectEntry.image_cover"/>
        </div>
        <!-- content -->
        <div class="w-full py-4 px-6 text-gray-800 flex flex-col justify-between">
          <div class="bg-purple-200 px-2 py-1 rounded-full border border-gray-200 max-w-xs text-center">
            <span class="font-semibold text-lg leading-tight truncate uppercase ">{{ projectEntry.genre }}</span>
          </div>
          <h3 class="text-gray-900 text-2xl font-bold leading-snug">"{{ projectEntry.title }}"</h3>
          <p class="font-medium">
            Directed by {{ projectEntry.director }}
          </p>
          <div class="bg-purple-300 px-2 py-1 rounded-lg border border-gray-200 max-w-xs text-center">
            <p class="font-sans">
              Role: {{ projectEntry.role }}
            </p>
          </div>
          <p class="text-base text-gray-700 tracking-wide font-semibold mt-2">
            Produced by {{ projectEntry.production }} for {{ projectEntry.for }}
          </p>
        </div>
      </div><!--/ card-->
    </div><!--/ flex-->

  </div>

</template>

<script>
import GetProjectsData from '../mixins/project-data'

export default {
  components: {},
  mixins: [
    GetProjectsData
  ],
  // props can come from query params from nav and that would load particular set
  params: {
    toDisplay: String
  },
  data() {
    return {
      currentProjectCategory: null,
      projectCategoryData: null,
      categoryButtons: [],
      dataReady: false,
    }
  },
  methods: {
    extractData() {
      this.projectCategoryData = this.projectsData.find(projectData => {
        return projectData.category === this.currentProjectCategory
      });
    },
    changeCurrentCategory(categoryName) {
      if (categoryName != undefined) {
        this.currentProjectCategory = categoryName
      }
      this.extractData()
      this.dataReady = true
      this.checkActiveCategory()
    },


    checkActiveCategory() {
/*      this.categoryButtons.forEach(button => {
        if (button.classList.contains('rounded-lg')){
          console.log('LOL')
          button.classList.remove("border-4", "border-purple-400", "rounded-lg")
        }


      })*/
          //button.class="transform hover:scale-110 motion-reduce:transform-none p-1"
      this.categoryButtons.forEach(button => {
        if (this.currentProjectCategory == button.id) {
          button.classList.add("border-4", "border-purple-400", "rounded-lg")
        }
        else {
          button.classList.remove("border-4", "border-purple-400", "rounded-lg")
        }

      })
    },
  },
  created() {
    //this.extractData()
  },


  mounted() {
    this.categoryButtons.push(document.getElementById('location-sound'))
    this.categoryButtons.push(document.getElementById('editorial-sound'))
    this.categoryButtons.push(document.getElementById('music-and-score'))

    if (this.$route.params.toDisplay == undefined) {
      this.changeCurrentCategory("location-sound")
      console.log('im here')
    } else {
      this.changeCurrentCategory(this.$route.params.toDisplay)

    }


    console.log(this.$route.params.toDisplay)
  }
}
</script>

<style scoped>
.closeAbout {
  color: #ccc;
  float: right;
  font-size: 3rem;
  left: 0;
}

.closeAbout:hover,
.closeAbout:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

</style>