<template>
<div>
  <div class="flex flex-col items-center justify-center min-h-screen bg-center bg-cover"
       :style="{'background-image':'url(/images/background/backdrop_3.jpg)'}">


    <div class="sm:text-4xl m-2 sm:m-4 opacity-60 z-10">

                <span class="closeAbout absolute left-0 top-0 h-16 w-16 sm:text-4xl mt-4 sm:mt-4  ..."><router-link
                    to="/"><svg
                    xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"/>
</svg>
        </router-link></span>


      <h1 class="font-sans text-3xl sm:text-4xl m-2 sm:m-4 leading-tight">
        Music
      </h1>

    </div>

    <!-- card -->
    <div class="flex flex-col md:flex-row overflow-hidden
                                        bg-white rounded-lg shadow-xl mt-2 w-full sm:w-1/2 mx-2 ">
      <!-- card content -->
      <div class="w-full py-4 px-6 text-gray-800 flex flex-col justify-between">

        <div class="flex items-center justify-center bg-gray-900 opacity-80 rounded-lg shadow-lg">
          <h3 class="md:text-3xl text-xl font-bold text-gray-200 py-3 px-6 text-center">Score for Singularity, a short film directed by Isher Sahota</h3>
        </div>

        <br/>
        <iframe width="100%" height="300" scrolling="no" frameborder="no" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/261039989&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
       </div>

    </div><!--/ card-->

    <!-- card -->
    <div class="flex flex-col md:flex-row overflow-hidden
                                        bg-white rounded-lg shadow-xl mt-4 w-full sm:w-1/2 mx-2 ">
      <!-- card content -->
      <div class="w-full py-4 px-6 text-gray-800 flex flex-col justify-between">

        <div class="flex items-center justify-center bg-gray-900 opacity-80 rounded-lg shadow-lg">
          <h3 class="md:text-3xl text-xl font-bold text-gray-200 py-3 px-6 text-center">A dark ambient,  IDM EP</h3>
        </div>

        <br/>
        <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/631789557&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>      </div>

    </div><!--/ card-->


  </div><!--/ flex-->
</div>
</template>

<script>

export default {
  components: {},

  mixins: [
  ],

  data() {
    return {
      currentProjectCategory: 'music',
      dataReady: false,
    }
  },

  methods: {
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>