<template>
  <div class="flex flex-col items-center justify-center min-h-screen bg-center bg-cover"
       :style="{'background-image':'url(/images/background/backdrop_2.jpg)'}">

    <div class="sm:text-4xl m-2 sm:m-4 opacity-60 z-10">

                <span class="closeAbout absolute left-0 top-0 h-16 w-16 sm:text-4xl mt-2 sm:mt-4"><router-link
                    to="/"><svg
                    xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"/>
</svg>
        </router-link></span>


      <h1 class="font-sans text-3xl sm:text-4xl m-2 sm:m-4 leading-tight">
        Production sound
      </h1>

    </div>


    <div v-if="dataReady"
         class="flex items-center justify-center min-h-screen bg-gray-600 opacity-80 rounded-2xl shadow-lg">

      <div class="flex flex-col flex-no wrap sm:table-row rounded-lg shadow-lg mb-8 sm:mb-0">
        <h3 class="text-3xl sm:text-4xl font-bold text-gray-200 py-6 px-6 text-center">Selected works</h3>

        <div class="align-middle inline-block shadow overflow-hidden bg-gray-200 shadow-dashboard px-8 pt-3 rounded-lg">

          <table class="">
            <thead class="bg-gray-600 text-gray-300">
            <tr class="">
              <th class="pl-6 sm:pl-10 sm:text-left sm:pl-6 text-left">Title</th>
              <th class="p-2 hidden sm:table-cell text-left">Director</th>
              <th class="p-2 hidden sm:table-cell text-left">Production</th>
              <th class="pr-3 sm:p-4 text-left">Client</th>
              <th class="pr-3 sm:p-4 text-left">Role</th>
            </tr>
            </thead>
            <tbody>
            <br/>

            <!--NARRATIVE-->
            <tr class="font-bold bg-gray-500 text-white rounded-md px-10 py-2 transition-colors w-full">
              <th colspan="5">{{ projectCategoryData.type[0].name }}</th>
            </tr>

            <tr v-for="projectEntry in projectCategoryData.type[0].projects" v-bind:key="projectEntry.index"
                class="border-2 border-gray-100">

              <td class="p-1 pl-2 sm:p-3 text-sm sm:text-lg ">


                <div class="flex align-items-center">
                  <div class="ml-3">
                    <div class="text-gray-700 p-1 sm:p-3 underline hover:text-gray-500">
                      <span v-if="projectEntry.link != ''" class="underline"><a :href="projectEntry.link"
                                                                                target="_blank">"{{
                          projectEntry.title
                        }}"</a></span>
                      <span v-else>"{{ projectEntry.title }}"</span>
                    </div>
                  </div>
                </div>
              </td>

              <td class="p-1 sm:p-3 text-sm sm:text-lg hidden text-gray-900 sm:table-cell">
                {{ projectEntry.director }}
              </td>
              <td class="p-3 text-sm sm:text-lg font-bold hidden sm:table-cell">
                {{ projectEntry.production }}
              </td>

              <td class="p-1 sm:p-3 text-sm sm:text-lg text-gray-900 font-bold">
                <div class="hidden sm:table-cell">
                <span>{{ projectEntry.for }}</span>
                </div>
                <div  class="table-cell sm:hidden">
                  <span v-if="projectEntry.for != 'N/A'">{{ projectEntry.for }}</span>
                  <span v-else>{{ projectEntry.production }}</span>
                </div>
              </td>

              <td class="p-1 pr-4 sm:p-3">
                <span class="text-gray-700 text-sm sm:text-lg">
                  <i class="text-left">{{ projectEntry.role }}</i>
                </span>
              </td>
            </tr>

            <!--DOCUMENTARY-->
            <tr class="font-bold bg-gray-500 text-white rounded-md px-10 py-2 transition-colors w-full">
              <th colspan="5">{{ projectCategoryData.type[1].name }}</th>
            </tr>

            <tr v-for="projectEntry in projectCategoryData.type[1].projects" v-bind:key="projectEntry.index"
                class="border-2 border-gray-100">

              <td class="p-1 pl-2 sm:p-3 text-sm sm:text-lg ">


                <div class="flex align-items-center">
                  <div class="ml-3">
                    <div class="text-gray-700 p-1 sm:p-3 underline hover:text-gray-500">
                      <span v-if="projectEntry.link != ''" class="underline"><a :href="projectEntry.link"
                                                                                target="_blank">"{{
                          projectEntry.title
                        }}"</a></span>
                      <span v-else>"{{ projectEntry.title }}"</span>
                    </div>
                  </div>
                </div>
              </td>

              <td class="p-1 sm:p-3 text-sm sm:text-lg hidden text-gray-900 sm:table-cell">
                {{ projectEntry.director }}
              </td>
              <td class="p-3 text-sm sm:text-lg font-bold hidden sm:table-cell">
                {{ projectEntry.production }}
              </td>
              <td class="p-1 sm:p-3 text-sm sm:text-lg text-gray-900 font-bold">
                <div class="hidden sm:table-cell">
                  <span>{{ projectEntry.for }}</span>
                </div>
                <div  class="table-cell sm:hidden">
                  <span v-if="projectEntry.for != 'N/A'">{{ projectEntry.for }}</span>
                  <span v-else>{{ projectEntry.production }}</span>
                </div>
              </td>
              <td class="p-1 pr-4 sm:p-3">
                <span class="text-gray-700 text-sm sm:text-lg">
                  <i class="text-left">{{ projectEntry.role }}</i>
                </span>
              </td>
            </tr>


            <!--COMMERCIAL-->
            <tr class="font-bold bg-gray-500 text-white rounded-md px-10 py-2 transition-colors w-full">
              <th colspan="5">{{ projectCategoryData.type[2].name }}</th>
            </tr>

            <tr v-for="projectEntry in projectCategoryData.type[2].projects" v-bind:key="projectEntry.index"
                class="border-2 border-gray-100">

              <td class="p-1 pl-2 sm:p-3 text-sm sm:text-lg ">


                <div class="flex align-items-center">
                  <div class="ml-3">
                    <div class="text-gray-700 p-1 sm:p-3 underline hover:text-gray-500">
                      <span v-if="projectEntry.link != ''" class="underline"><a :href="projectEntry.link"
                                                                                target="_blank">"{{
                          projectEntry.title
                        }}"</a></span>
                      <span v-else>"{{ projectEntry.title }}"</span>
                    </div>
                  </div>
                </div>
              </td>

              <td class="p-1 sm:p-3 text-sm sm:text-lg hidden text-gray-900 sm:table-cell">
                {{ projectEntry.director }}
              </td>
              <td class="p-3 text-sm sm:text-lg font-bold hidden sm:table-cell">
                {{ projectEntry.production }}
              </td>
              <td class="p-1 sm:p-3 text-sm sm:text-lg text-gray-900 font-bold">
                <div class="hidden sm:table-cell">
                  <span>{{ projectEntry.for }}</span>
                </div>
                <div  class="table-cell sm:hidden">
                  <span v-if="projectEntry.for != 'N/A'">{{ projectEntry.for }}</span>
                  <span v-else>{{ projectEntry.production }}</span>
                </div>
              </td>
              <td class="p-1 pr-4 sm:p-3">
                <span class="text-gray-700 text-sm sm:text-lg">
                  <i class="text-left">{{ projectEntry.role }}</i>
                </span>
              </td>
            </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div
        class="w-full p-8 m-2 w-1/3 opacity-20 md:max-w-sm md:mx-auto">
      <div class="overflow-auto flex flex-col items-center justify-center ">
        <p>
          <span class="leading-relaxed underline text-gray-50 hover:text-gray-100 px-4"><a href="https://www.imdb.com/name/nm5906163" target="_blank">Imbd</a></span>
          <span class="leading-relaxed underline text-gray-50 hover:text-gray-100 px-4"><a href="https://www.instagram.com/phantom_sonix" target="_blank">Instagram</a></span>
          <span class="leading-relaxed underline text-gray-50 hover:text-gray-100 px-4"><a href="https://www.instagram.com/phantom_sonix" target="_blank">Kit List</a></span>

        </p>
      </div>
    </div>
  </div>

</template>

<script>
import GetProjectsData from '../mixins/project-data'

export default {
  components: {},

  mixins: [
    GetProjectsData
  ],

  data() {
    return {
      currentProjectCategory: 'production-sound',
      dataReady: false,
    }
  },

  methods: {
    extractData() {
      this.projectCategoryData = this.projectsData.find(projectData => {
        return projectData.category === this.currentProjectCategory
      });
      this.dataReady = true
    },

  },
  mounted() {
    this.extractData()
  }
}
</script>

<style scoped>

</style>