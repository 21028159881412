export default {
    data() {
        return {
            projectsData: []
        }
    },
    created() {
        this.projectsData = this.getProjectsData()
    },
    methods: {
        getProjectsData() {
            return [
                {
                    category: "production-sound",
                    description: "These location sound projects",
                    type: {
                        0: {
                            name: "Narrative",
                            projects: {
                                0: {
                                    title: "Utopia",
                                    director: "Adam Thirlwell",
                                    production: "Revolution Films",
                                    for: "CH4 Random Acts",
                                    role: "Production Sound Mixer",
                                    link: "https://www.youtube.com/watch?v=9lo_b2O1ODo",
                                },
                                1: {
                                    title: "Swimmers",
                                    director: "Sally El-Hosaini",
                                    production: "Netflix Studios",
                                    for: "N/A",
                                    role: "2nd Assistant Sound",
                                    link: "",
                                },
                                2: {
                                    title: "The Fall of the Krays",
                                    director: "Zackary Adler",
                                    production: "Saracen Films",
                                    for: "N/A",
                                    role: "Boom Operator",
                                    link: "",
                                },
                            },
                        },
                        1: {
                            name: "Documentary",
                            projects: {
                                0: {
                                    title: "London Lockdown",
                                    director: "Namak Khoshnow",
                                    production: "BBC",
                                    for: "BBC",
                                    role: "Sound Recordist",
                                    link: "https://vimeo.com/426010766",
                                },
                                1: {
                                    category: "Documentary",
                                    title: "Uncle Howard",
                                    director: "Aaron Brookner",
                                    production: "Pinball London",
                                    for: "N/A",
                                    role: "Sound Recordist",
                                    link: "https://vimeo.com/ondemand/unclehowarduk",
                                },
                                2: {
                                    category: "Documentary",
                                    title: "Sisterhood",
                                    director: "Dominiziana De Flavio",
                                    production: "Alfa Multimedia",
                                    for: "N/A",
                                    role: "Sound Recordist",
                                    link: "https://www.youtube.com/watch?v=aURn_1WBNgc&t=8s",
                                },
                                3: {
                                    category: "Documentary",
                                    title: "Hoodies All Summer",
                                    director: "Harry Barber",
                                    production: "Dadbod Films",
                                    for: "Kano",
                                    role: "Sound Recordist",
                                    link: "https://vimeo.com/harrybarber",
                                },
                            }
                        },
                        2: {
                            name: "Commercials and Branded Content",
                            projects: {
                                7: {
                                    title: "3D.Op.Lite",
                                    director: "Jonas Heig",
                                    production: "Radical Media",
                                    for: "Reebok",
                                    role: "Sound Recordist",
                                    link: "https://vimeo.com/292671933",
                                },
                                8: {
                                    title: "I will!",
                                    director: "Harry Barber",
                                    production: "Dadbod Films",
                                    for: "Under Armour",
                                    role: "Sound Recordist",
                                    link: "https://vimeo.com/215519148",
                                },
                                9: {
                                    title: "Don't Give Up, Rise Up - Moses Boyd",
                                    director: "Sam Davis",
                                    production: "Rattling Stick",
                                    for: "Buxton water",
                                    role: "Sound Recordist",
                                    link: "https://www.youtube.com/watch?v=Y5L-EFIgy70",
                                },
                                10: {
                                    title: "The Dean of Downtime",
                                    director: "Tom Brown",
                                    production: "Burning Reel",
                                    for: "NatWest",
                                    role: "Sound Recordist",
                                    link: "https://vimeopro.com/burningreel/tom-brown-wearescocial/video/289829063",
                                },
                                11: {
                                    title: "Mykki Blanco on white supremacy",
                                    director: "Florian Joahn",
                                    production: "Compulsory.",
                                    for: "Dazed",
                                    role: "Sound Recordist",
                                    link: "https://www.youtube.com/watch?v=ozMZ1TXGM40&list=RDozMZ1TXGM40&start_radio=1",
                                },
                                12: {
                                    title: "Unleash",
                                    director: "Harry Barber",
                                    production: "DadBod Films",
                                    for: "River Island",
                                    role: "Sound Recordist",
                                    link: "https://vimeo.com/551370199",
                                },
                            }
                        }

                    },

                },
                {
                    category: "editorial-sound",
                    description: "These are editorial sound design projects",
                    project: {
                        0: {
                            title: "Dau",
                            summary: "12 Feature Films and series",
                            role: "Sound Design & Edit",
                            image_cover: "/images/projects/Dau.jpg",
                            video_path: "https://www.youtube.com/embed/RdwEglW8EX8",
                        },
                        1: {
                            title: "Utopia",
                            summary: "Short Film",
                            role: "Sound Design",
                            image_cover: "/images/projects/Utopia.jpg",
                            video_path: "https://www.youtube.com/watch?v=9lo_b2O1ODo",
                        },
                        2: {
                            title: "Sisterhood",
                            summary: "Feature Documentary",
                            role: "Sound Design and Mix",
                            image_cover: "/images/projects/Sisterhood.jpg",
                            video_path: "https://www.youtube.com/embed/aURn_1WBNgc",
                        },
                        3: {
                            title: "Singularity",
                            summary: "Short Film",
                            role: "Sound Design and Music",
                            image_cover: "/images/projects/Singularity.jpg",
                            video_path: "https://player.vimeo.com/video/165282671",
                        },
                        4: {
                            title: "Made",
                            summary: "360° Documentary",
                            role: "Sound Design and Mix",
                            image_cover: "/images/projects/Made.jpg",
                            video_path: "https://player.vimeo.com/video/562674636",
                        },
                        5: {
                            title: "Aurora",
                            summary: "AR experience",
                            role: "Sound Design",
                            image_cover: "/images/projects/Aurora.jpg",
                            video_path: "https://player.vimeo.com/video/260738698",
                        },
                        6: {
                            title: "Basilisk",
                            summary: "Short",
                            role: "Sound Design and Music",
                            image_cover: "/images/projects/Basilisk.jpg",
                            video_path: "https://player.vimeo.com/video/117258536",
                        },
                    }
                },
                {
                    category: "music-and-score",
                    description: "These are music projects",
                    project: {
                        0: {
                            summary: "Score for Singularity, a short film directed by Isher Sahota",
                            image_cover: "/images/projects/Aurora.jpg",
                            audio_path: "https://player.vimeo.com/video/260738698",
                        },
                        1: {
                            title: "Ritual Unmastered",
                            director: "Adam Thirlwell",
                            production: "Revolution Films",
                            for: "CH4 Random Acts",
                            role: "Sound Design",
                            summary: "This is what happened",
                            image_cover: "/images/malevich_square.jpeg",
                            genre: "WIP",
                            audio_path: "",
                            has_video: "True",
                            video_path: "https://vimeo.com/299481669",
                        },
                    }
                },
            ]
        }
    }
}
