<template>
  <div id="about-modal" class="modal" style="background: rgba(255, 255, 255, 0.5)">

    <div class="modal-content">

      <div class="relative h-16 w-16 ...">
        <span class="closeAbout absolute left-0 top-0 h-16 w-16 ..." v-on:click="clickedClose">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <line x1="10" y1="10" x2="50" y2="50" stroke="black" stroke-width="2"  />
    <line x1="50" y1="10" x2="10" y2="50" stroke="black" stroke-width="2" />
</svg>
</span>
      </div>

      <div class="px-10 py-12 bg-white rounded-lg shadow-lg">

                <div id="video-player" class="relative" style="padding-top: 56.25%">
                  <iframe class="absolute inset-0 w-full h-full" :src="videoToEmbed" frameborder="0"></iframe>
                </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    videoUrl: String
  },
  data() {
    return {
      videoToEmbed: null
    }
  },
  methods: {
    clickedClose() {
      this.$emit('closed')

    },

  },
  mounted() {
    window.addEventListener('keyup', this.clickedClose)
    this.videoToEmbed = this.videoUrl
    console.log(this.props.videoUrl)

/*
    window.addEventListener('click', function(e) {
        console.log(e)
      })
*/


    }

}
</script>

<style scoped>


.modal {
  /*
    display: none;
  */
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  pointer-events: auto;
  background-color: #fff;
}


.closeAbout{
  color: #ccc;
  float: right;
  font-size: 3rem;
  left: 0;
}

.closeAbout:hover,
.closeAbout:focus
{
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>