<template>
  <div class="flex flex-col items-center justify-center min-h-screen bg-center bg-cover"
       :style="{'background-image':'url(/images/background/backdrop_2.jpg)'}">


    <div class="sm:text-4xl m-2 sm:m-4 opacity-60 z-10">

                <span v-if="!displayVideo" class="closeAbout absolute left-0 top-0 h-16 w-16 sm:text-4xl mt-2 sm:mt-4"><router-link
                    to="/"><svg
                    xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"/>
</svg>
        </router-link></span>


      <h1 class="font-sans text-3xl sm:text-4xl m-2 sm:m-4 leading-tight">
        Editorial sound
      </h1>

    </div>
    <div v-if="dataReady" class="container w-full lg:w-1/2 mx-a auto flex flex-col">

      <div v-for="projectEntry in projectCategoryData.project" v-bind:key="projectEntry.index" class="flex flex-col flex-row overflow-hidden
                                        bg-white rounded-lg justify-center ">

        <div v-on:click="toggleVideo(projectEntry.video_path)">

          <div class="relative">
            <a v-if="!displayVideo" class="absolute inset-0 z-10 bg-white text-center flex flex-col items-center justify-center opacity-0 hover:opacity-100 bg-opacity-90 duration-300">
              <h1 class=tracking-wider>"{{ projectEntry.title }}"</h1>
              <p class="mx-auto">{{ projectEntry.summary }}</p>
              <p class="mx-auto">{{ projectEntry.role }}</p>
            </a>
            <div class="relative">
              <div class="h-128 flex flex-wrap content-center">
                <img :src="projectEntry.image_cover" class="mx-auto" alt="">
              </div>
            </div>
          </div>

        </div>

      </div>

    </div><!--/ flex-->
    <div v-if="displayVideo">
      <Video v-bind:videoUrl="currentVideoUrl" v-on:closed="toggleVideo"/>
    </div>
  </div>
</template>

<script>
import GetProjectsData from '../mixins/project-data'
import Video from "../components/Video";

export default {
  components: {Video},

  mixins: [
    GetProjectsData
  ],

  data() {
    return {
      currentProjectCategory: 'editorial-sound',
      dataReady: false,
      displayVideo: false,
      currentVideoUrl: null,
    }
  },

  methods: {
    extractData() {
      this.projectCategoryData = this.projectsData.find(projectData => {
        return projectData.category === this.currentProjectCategory
      });
      this.dataReady = true
    },
    toggleVideo(videoUrl) {
      this.currentVideoUrl = videoUrl
      console.log(this.currentVideoUrl)
      this.displayVideo = !this.displayVideo

    },
  },

  mounted() {
    this.extractData()
  }
}
</script>

<style scoped>

</style>